@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");

.App {
  text-align: center;
  font-family: "Press Start 2P", "VT323";
  color: #fff;
  background-color: #538ea5;
}

* {
  box-sizing: border-box;
}

.overlay {
  /* opacity: 0.85; */
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /* z-index:10;
  left: 0;
  top: 0;
  position: fixed; */
}

.moving-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url("./assets/background/parallax-bg.gif"); */
  background-image: url("../assets/background/bg.png");
  filter: brightness(30%);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 40%;
}
